import React from "react"
import { Link } from "gatsby"

const Sidebar = ({ posts }) => {
  const linkClassNames = "block mb-1 hover:underline"

  return (
    <div className="md:col-span-4 text-lg">
      <h3 className="font-bold text-gray-800 mb-1">Site Builders</h3>
      <Link to="/integrations/wix/" className={linkClassNames}>
        Wix
      </Link>
      <Link
        to="/integrations/webstarts-payments-subscriptions/"
        className={linkClassNames}
      >
        WebStarts
      </Link>

      <h3 className="font-bold text-gray-800 mb-1 mt-4">App Builders</h3>
      <Link
        to="/integrations/bubble-payments-subscriptions/"
        className={linkClassNames}
      >
        Bubble
      </Link>
      <Link
        to="/integrations/glide-apps-payments-subscriptions/"
        className={linkClassNames}
      >
        Glide Apps
      </Link>

      <h3 className="font-bold text-gray-800 mb-1 mt-4">Tools</h3>
      <Link to="/integrations/qwilr/" className={linkClassNames}>
        Qwilr
      </Link>
      <Link to="/integrations/zoom/" className={linkClassNames}>
        Zoom
      </Link>

      <h3 className="font-bold text-gray-800 mb-1 mt-4">Guides</h3>
      {posts.map(({ node }) => (
        <Link
          key={node.slug.current}
          to={`/guides/${node.slug.current}/`}
          className={linkClassNames}
        >
          {node.title}
        </Link>
      ))}
    </div>
  )
}

export default Sidebar
